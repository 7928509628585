import React from "react";
import type { AppProps } from "next/app";
import "../styles/globals.css";

export default function App({ Component, pageProps }: AppProps) {
  // TODO (MRKP-2541): REMOVE this workaround.  Find a better solution.
  /**
   * This workaround was added to address MRKP-2540
   * next.js router is redirecting back to original url
   *
   * Notes:
   * - this works only because of useEffect order (parent useEffects fire AFTER children)
   *   - this should gaurantee that EmailCaputure history rewrite happens before we capture initialUrl
   * - it may/will break other things in the future.
   *   - it will disallow history rewrites on the page (tabbed interfaces like GameSpecs may not work)
   */
  const initialURL = React.useRef<string | null>(null);
  React.useEffect(() => {
    if (!initialURL.current) {
      initialURL.current = window.location.href.replace(
        window.location.origin,
        ``,
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((history.replaceState as any).replaced) {
      return;
    }

    const originalReplaceState = history.replaceState;
    history.replaceState = (...args) => {
      // Disallow replaceState calls other than adding trailing slash.
      if (
        initialURL.current !== args[2] &&
        initialURL.current + "/" !== args[2]
      ) {
        return;
      }
      // Uncomment this line to debug replaceState calls.
      // eslint-disable-next-line no-debugger
      // debugger;
      originalReplaceState.bind(history)(...args);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (history.replaceState as any).replaced = true;
    return () => {
      history.replaceState = originalReplaceState;
    };
  }, []);

  return <Component {...pageProps} />;
}
